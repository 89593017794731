.subtitle{
    position: relative;
    width: 561px;
    height: 34px;
    margin-top: 60px;
    margin-left: -200px;
    left: 0px;
    top: 0px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #121127;
}

.words{
    position: relative;
    width: 936px;
    height: 250px;
    margin-top: 20px;
    margin-left: -200px;

    /* Body/xl */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    /* or 36px */

    /* Grayscale 900 Opacity/56% */

    color: rgba(18, 17, 39, 0.56);
}

.words_email{
    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    color: #A22873;
}