.product_content_frame {
    position: static;
    width: 100%;
}

.product_content {
    width: 100%;
    height: 4851px;

    background: #FFFFFF;
}

.product_photos {
    position: absolute;
    width: 1040px;
    /*left: 200px;*/
    top: 500px;
    /*height: 1000px;*/
    /*background: #5ac9e5;*/
}

.product_content_words {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: relative;
    margin: 0 auto;
    width: 552px;
    height: 155px;
    top: 164px;
}

.product_content_words_title {
    position: relative;
    width: 552px;
    height: 67px;
    margin: 0 auto;
    top: 0px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 140%;
    /* or 67px */
    text-align: center;
    color: #A22873;
}

.product_content_words_comment {
    position: static;
    width: 552px;
    height: 64px;
    margin-top: 30px;

    /* Body / lg */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */

    text-align: center;

    /* Grayscale 900 Opacity/56% */

    color: rgba(18, 17, 39, 0.56);
}

.product_content_detail_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    /* Drop Shadow/High */

    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 12px;
}

.product_content_img_frame {
    position: static;
    width: 360px;
    height: 242px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #9E4CE7, #9E4CE7), #9E4CE7;
    border-radius: 12px 12px 0px 0px;
}

.product_content_img_frame_normal {
    position: static;
    width: 360px;
    height: 242px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5C8BE4;
    border-radius: 12px 12px 0px 0px;
}

.product_content_detail_comments {
    position: static;
    width: 360px;
    height: 137px;
    left: 0px;
    top: 242px;
    margin-top: 10px;
}

.product_content_detail_comments_intro {
    position: static;
    width: 360px;
    height: 101px;
    left: 24px;
    top: 12px;
}

.product_content_detail_comments_intro_title {
    position: static;
    width: 500px;
    height: 30px;
    left: 24px;
    top: 12px;
    margin-left: 20px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;

    color: #121127;
}

.product_content_detail_comments_intro_source {
    position: static;
    width: 500px;
    height: 21px;
    left: 24px;
    top: 48px;
    margin-left: 20px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;

    color: #121127;
}

.product_content_detail_comments_intro_class {
    position: static;
    width: 32px;
    height: 32px;
    left: 24px;
    top: 81px;
    margin-left: 20px;
    margin-top: 20px;

}

.product_content_detail_comments_intro_sex {
    position: static;
    width: 32px;
    height: 32px;
    left: 63px;
    top: 81px;
    margin-left: 10px;
}

.product_content_prize_frame {
    position: static;
    width: 83px;
    height: 31px;
    left: 253px;
    top: 320px;
}

.product_content_detail_prize_icon {
    position: static;
    width: 24px;
    height: 24px;
    left: 63px;
    margin-left: 170px;
}

.product_content_detail_prize_words {
    position: static;
    width: 24px;
    height: 24px;
    left: 63px;
    margin-left: 10px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    /* or 39px */
    color: #A22873;
}

.product_content_detail_prize_words_eth {
    position: static;
    width: 24px;
    height: 24px;
    left: 63px;
    margin-left: 10px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    /* or 39px */
    color: #5AC9E5;
}

.product_card_frame {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*padding: 0px;*/

    position: relative;
    margin: 0 auto;
    width: 1140px;
    top: 200px;
}

.product_page {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*padding: 0px;*/

    position: relative;
    margin: 0 auto;
    width: 400px;
    top: 100px;
}

.product_card {
    float: left;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    cursor: pointer;

    width: 360px;
    height: 379px;
    background: #FFFFFF;

    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 12px;

    min-width: 150px;
    margin-left: 20px;
    margin-top: 20px;
}

.product_item_frame {
    position: static;
    width: 1040px;
    height: 4200px;
    margin: 164px auto;
}

.product_item_frame_absolute {
    position: absolute;
    width: 1040px;
    height: 1424px;
}

.product_item_photo {
    position: absolute;
    width: 374px;
    height: 424px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(0deg, #9E4CE7, #9E4CE7), #9E4CE7;
    border-radius: 12px;
}

.product_item_photo_normal {
    position: absolute;
    width: 374px;
    height: 424px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #5C8BE4;
    border-radius: 12px;
}

.product_item {
    position: absolute;
    width: 363px;
    left: 450px;
}

.product_item_photo_title {
    width: 900px;
    height: 76px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 180%;
    /* identical to box height, or 76px *

    /* Grayscale / 900 */

    color: #121127;
}

.product_item_icons {
    width: 72px;
    height: 32px;
}

.product_item_icon1 {
    width: 32px;
    height: 32px;
}

.product_item_icon2 {
    width: 32px;
    height: 32px;
}

.products-label-title {
    width: 130px;
    height: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    color: rgba(18, 17, 39, 0.56);
}

.product_words {
    width: 450px;
    height: 28px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;

    color: #121127;
}

.product_platform {
    position: absolute;
    width: 140px;
    height: 36px;
    /*left: 600px;*/
    text-align: center;

    background: #F9F9FB;
    border: 1px solid #C0C0CA;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 36px;

    cursor: pointer;
    /* or 25px */

    color: #121127;
}

.product_platform_selected {
    position: absolute;
    width: 140px;
    height: 36px;
    /*left: 600px;*/
    /*margin-left: 40px;*/
    text-align: center;

    background: #F9F9FB;
    border: 1px solid #A22873;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 36px;
    /* or 25px */

    color: #A22873;
}


.product_prize_icons {
    width: 161px;
    height: 45px;
}

.product_prize_split {
    left: 80px;
    top: 5px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;

    color: #121127;
}

.button_group{
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items: center;
}

.buy_button_empty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;

    background: linear-gradient(180deg, #D24C9F 0%, #A22873 100%);
    box-shadow: 0px 14px 30px rgba(179, 13, 72, 0.16);
    border-radius: 38px;
    border: none;
}

.buy_button_selected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;

    background: linear-gradient(180deg, #D24C9F 0%, #A22873 100%);
    box-shadow: 0px 14px 30px rgba(179, 13, 72, 0.16);
    border-radius: 38px;
    border: none;
}

.buy_button_words {
    position: static;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    /* Black & White/White */

    color: #FFFFFF;
}

.line0 {
    position: absolute;
    width: 324px;
    height: 0px;
    left: 107px;
    top: 151px;

    border: 1px solid rgba(162, 40, 115, 0.4);
}

.story_vector {
    position: absolute;
    width: 45px;
    height: 31px;
    left: 375px;
    top: 98px;
}

.story_img {
    position: absolute;
    width: 250px;
    /*height: 219px;*/
    left: 702px;
    top: 117px;
}

.line1 {
    position: absolute;
    width: 352px;
    height: 0px;
    top: 507px;
    left: 0px;

    border: 1px solid rgba(0, 0, 0, 0.15);
}

.line2 {
    position: absolute;
    width: 352px;
    right: 0px;
    top: 507px;

    border: 1px solid rgba(0, 0, 0, 0.15);
}

.product_history_frame {
    position: absolute;
    width: 1040px;
    height: 453px;
    background: #F9F9FB;
}

.product_history_words {
    position: absolute;
    width: 542px;
    height: 283px;
    /*left: 307px;*/
    /*top: 825px;*/
}

.product_history_words_title {
    position: absolute;
    width: 255px;
    height: 65px;
    left: 112px;
    top: 80px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 180%;
    /* identical to box height, or 65px */

    color: #A22873;
}

.product_history_words_content {
    position: absolute;
    width: 537px;
    height: 196px;
    left: 112px;
    top: 167px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    /* or 28px */


    color: #121127;
}

.product_details {
    position: relative;
    width: 285px;
    height: 58px;
    top: 475px;
    margin: 0 auto;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 180%;
    /* identical to box height, or 65px */


    color: #A22873;
}

.product_detail_words {
    position: relative;
    width: 842px;
    height: 56px;
    top: 490px;
    margin: 0 auto;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    /* or 28px */

    text-align: center;

    color: rgba(18, 17, 39, 0.56);
}

.product_detail_photos {
    position: absolute;
    /*width: 842px;*/
    height: 1000px;
    top: 550px;
    margin: 0 auto;
}