.App {
    text-align: center;
    width: 100%;
    /* display: flex; */
}

.home {
    position: static;
    width: 100%;
    height: 900px;
    left: 0px;
    top: 0px;

    background: #FFF4E6;
}

.header {
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 0px;
}

.logo {
    position: absolute;
    width: 147px;
    height: 31.68px;
    left: 156px;
    top: 34px;
}

.header_frame {
    /*position: absolute;*/
    float: left;
    margin-top: 30px;
    margin-left: 400px;
}

.header_wallet {
    /*position: absolute;*/
    float: right;
    margin-top: 30px;
    margin-right: 80px;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* 容器 <div> - 需要定位下拉内容 */
.dropdown {
    position: relative;
    display: inline-block;
}

/* 下拉内容 (默认隐藏) */
.dropdown-content {
    display: none;
    position: absolute;
    left: -50px;
    background-color: #f9f9f9;
    width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.dropdown-content_default {
    display: none;
    position: absolute;
    left: -50px;
    background-color: #f9f9f9;
    width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;

    color: #222222;
}

/* 下拉菜单的链接 */
.dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* 鼠标移上去后修改下拉菜单链接颜色 */
.dropdown-content div:hover {
    background-color: #f1f1f1;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #A22873;
}

/* 在鼠标移上去后显示下拉菜单 */
.dropdown:hover .dropdown-content {
    display: block;
}

/* 当下拉内容显示后修改下拉按钮的背景颜色 */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.button_selected{
    align-items: center;
    /*padding: 8px 24px;*/

    position: static;
    width: 103px;
    height: 40px;
    left: 0px;
    top: 0px;

    background: #A22873;
    border-radius: 6px;
    border: none;
}

.button_wallet{
    align-items: center;
    /*padding: 8px 24px;*/

    position: static;
    width: 150px;
    height: 40px;
    left: 0px;
    top: 0px;

    background: #A22873;
    border-radius: 6px;
    border: none;
}

.icon_wallet{
    align-items: center;

    position: static;
    left: 0px;
    top: 0px;
}

.word_selected{
    /*position: static;*/
    width: 100%;
    height: 24px;
    left: 24px;
    top: 8px;
    text-align:center;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /*align-items: center;*/

    color: #FFFFFF;
}

.wallet_selected{
    /*position: static;*/
    width: 140px;
    /*height: 24px;*/
    /*top: 8px;*/

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: #FFFFFF;
}

.button_empty{
    display: inline;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;

    position: static;
    width: 91px;
    height: 40px;
    left: 206px;
    top: 0px;

    background: transparent;
    border-radius: 6px;
    border: none;
}

.word_empty{
    position: static;
    width: 43px;
    height: 24px;
    left: 24px;
    top: 8px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #121127;
}

.button_product {
    position: static;
    width: 103px;
    height: 40px;
    left: 0px;
    top: 0px;

    background: #A22873;
    border-radius: 6px;
}

.button_DAO {
    position: static;
    width: 79px;
    height: 40px;
    left: 115px;
    top: 0px;

    border-radius: 6px;
}

.button_about {
    position: static;
    width: 91px;
    height: 40px;
    left: 206px;
    top: 0px;
    border-radius: 6px;
}

.slogan_frame {
    /* Auto Layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: relative;
    width: 744px;
    margin: 0 auto;
    height: 282px;
    top: 289px;
}

.slogan_words {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    width: 744px;
    height: 190px;
    margin: -20px;
}

.slogan_words_title {
    position: static;
    white-space: pre-wrap;
    word-break: normal;
    word-wrap: break-word;
    display: inline-block;
    overflow: hidden;
    width: 600px;
    height: 134px;

    /* Display / 2 */

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 120%;
    /* or 67px */
    color: #A22873;
}

.slogan_words_comment {
    /* Digital creation governed by DAO */
    position: relative;
    width: 744px;
    height: 36px;
    left: 0px;
    top: 30px;

    /* Body/xl */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    /* identical to box height, or 36px */

    text-align: center;
    /* Grayscale 900 Opacity/72% */

    color: #A22873;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.slogan_buttons{
    /*!* Auto Layout *!*/

    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    /*padding: 0px;*/

    position: relative;
    width: 400px;
    height: 52px;
    top: 60px;

    border-radius: 9px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.slogan_buttons_join{
    position: relative;
    width: 180px;
}

.slogan_buttons_join_selected{
    position: relative;
    width: 180px;
    top: -4px;
}

.slogan_buttons_voting{
    position: relative;
    width: 180px;
}

.slogan_buttons_voting_selected{
    position: relative;
    width: 180px;
    top: -4px;
}

.blur_yellow{
    position: absolute;
    width: 482px;
    height: 482px;
    left: 33%;
    top: 227px;

    background: #FFD390;
    opacity: 0.4;
    filter: blur(200px);
}

.blur_red{
    position: absolute;
    width: 482px;
    height: 482px;
    left: 46%;
    top: 227px;

    background: #FF96D5;
    opacity: 0.4;
    filter: blur(200px);
}

.star{
    position: absolute;
    width: 173.78px;
    height: 173.78px;
    left: 27.7%;
    top: 582px;

    transform: rotate(15.84deg);
}

/* hazelnut */
.hazelnut{
    position: absolute;
    width: 361px;
    height: 361px;
    left: 72.7%;
    top: 330px;
}


/* cash */
.cash{
    position: absolute;
    width: 74.01px;
    height: 74.01px;
    left: 10.55%;
    top: 343px;
}

/* helix-1 */
.helix_1{
    position: absolute;
    width: 68.75px;
    height: 68.75px;
    left: 69.4%;
    top: 524px;
}

/* helix-2 */
.helix_2{
    position: absolute;
    width: 47.66px;
    height: 66.03px;
    left: 22.9%;
    top: 385.34px;
}

/* helix-3 */
.helix_3{
    position: absolute;
    width: 26.71px;
    height: 26.71px;
    left: 7.7%;
    top: 545px;
}

/* coin-2 */
.coin_2{
    position: absolute;
    width: 85.62px;
    height: 95.19px;
    left: 59.2%;
    top: 691px;
}

/* coin-1 */
.coin_1{
    position: absolute;
    width: 61.97px;
    height: 68.9px;
    left: 28%;
    top: 154px;
}

/* doughnut-1 */
.doughnut_1{
    position: absolute;
    width: 89px;
    height: 79px;
    left: 87.5%;
    top: 691px;
}

/* doughnut-2 */
.doughnut_2{
    position: absolute;
    width: 89px;
    height: 79px;
    left: 17.3%;
    top: 510px;
}

/* doodle-6 1 */
.doodle_61{
    position: absolute;
    width: 62.9px;
    height: 62.9px;
    left: 96.3%;
    top: 347px;
}


/* doodle-7 1 */
.doodle_71{
    position: absolute;
    width: 113.48px;
    height: 113.48px;
    left: 2.7%;
    top: 654px;
}


/* doodle-7 2 */
.doodle_72{
    position: absolute;
    width: 113.48px;
    height: 113.48px;
    left: 69.7%;
    top: 138px;
}

.product {
    position: relative;
    width: 100%;
    height: 650px;
    left: 0px;
    /*top: 900px;*/

    background: #FFFFFF;
}

.product_frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: relative;
    margin: 0 auto;
    width: 1004px;
    height: 455px;
    top: 98px;
}

.product_intro{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: static;
    width: 1004px;
    height: 171px;
    left: calc(50% - 1004px/2);
    top: 0px;
}

.product_intro_title{
    /* Product */

    position: static;
    width: 1004px;
    height: 67px;
    left: calc(50% - 1004px/2);
    top: 0px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-size: 48px;
    line-height: 140%;
    /* or 67px */

    text-align: center;
    margin-bottom: 20px;
    color: #A22873;
}

.product_intro_comments{
    position: static;
    width: 980px;
    /*height: 72px;*/
    left: calc(50% - 980px/2);
    top: 99px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 180%;
    /* or 36px */

    text-align: center;

    /* Grayscale 900 Opacity/56% */

    color: rgba(18, 17, 39, 0.56);
}

.product_icon_frame{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 56px;

    position: static;
    width: 850px;
    height: 136px;
    left: 77px;
    margin-top: 48px;
}

.product_wearables{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: static;
    width: 96px;
    height: 136px;
    /*left: 56px;*/
    top: 0px;
}

.product_scenes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: static;
    width: 96px;
    height: 136px;
    margin-left: 100px;
    top: 0px;
}

.product_others{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: static;
    width: 96px;
    height: 136px;
    margin-left: 100px;
    top: 0px;
}

.product_avatars{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    position: static;
    width: 96px;
    height: 136px;
    margin-left: 100px;
    top: 0px;
}

.button_explore{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 34px;

    position: relative;
    height: 130px;
    margin-top: 50px;
}

.button_explore_selected{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 34px;

    position: relative;
    height: 130px;
    margin-top: 46px;
}

.DAO_frame{
    position: relative;
    width: 100%;
    height: 800px;
    left: 0px;
    background: #F2F3FF;
}

.helix_4{
    position: absolute;
    width: 55px;
    /*height: 50.13px;*/
    left: 52.1%;
    top: 700px;
}

.doughnut_3{
    position: absolute;
    /*width: 73px;*/
    height: 64px;
    left: 52.1%;
    top: 68px;
}

.comment1{
    position: absolute;
    /*width: 308px;*/
    height: 140px;
    left: 57.6%;
    top: 256px;
}

.comment2{
    position: absolute;
    /*width: 357px;*/
    height: 230px;
    left: 59.3%;
    top: 129px;
}

.comment3{
    position: absolute;
    /*width: 341px;*/
    height: 170px;
    left: 65.6%;
    top: 372px;
}

.comment4{
    position: absolute;
    /*width: 216px;*/
    height: 160px;
    left: 38.2%;
    top: 450px;
}

.comment5{
    position: absolute;
    /*width: 278px;*/
    height: 180px;
    left: 31.9%;
    top: 204px;
}

.saly{
    position: absolute;
    width: 507.62px;
    height: 507.62px;
    left: 40.2%;
    top: 245px;
}

.hazelnut2{
    position: absolute;
    /*width: 666px;*/
    height: 300px;
    left: 80.6%;
    top: 425px;
}

.DAO_words_frame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    position: absolute;
    width: 456px;
    height: 323px;
    left: 10.6%;
    top: 239px;
}

.DAO_inner_words_frame{
    position: static;
    width: 456px;
    height: 223px;
}

.DAO_title{
    position: static;
    width: 456px;
    height: 67px;
    text-align: left;

    font-family: Poppins-Bold;
    font-style: normal;
    font-size: 48px;
    line-height: 140%;
    /* or 67px */
    color: #A22873;
}

.DAO_comments{
    position: static;
    width: 456px;
    height: 15px;
    text-align: left;
    margin-top: 20px;

    /* Body/xl */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    /* or 36px */

    /* Grayscale 900 Opacity/56% */
    color: rgba(18, 17, 39, 0.56);
}

.DAO_button{
    position: relative;
    /*width: 153px;*/
    height: 110px;
    margin-left: -27px;
    margin-top: 40px;
}

.DAO_button_selected{
    position: relative;
    /*width: 153px;*/
    height: 110px;
    margin-left: -27px;
    margin-top: 36px;
}

.footer{
    position: absolute;
    width: 100%;
    height: 228px;

    background: #F9F9FB;
}

.connect_frame{
    position: absolute;
    width: 800px;
    height: 52px;
    /*left: 10.8%;*/
    top: 48px;
}

.connect{
    position: absolute;
    width: 400px;
    height: 45px;
    top: 51px;

    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */

    text-align: center;

    color: #A22873;
}

.connect_input{
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    padding: 6px 16px 5px;

    position: absolute;
    width: 382px;
    height: 52px;
    left: 180px;
    top: 48px;

    background: #F9F9FB;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.connect_send_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 34px;

    position: absolute;
    width: 160px;
    height: 52px;
    margin-left: 600px;
    /*left: 600px;*/
    top: 48px;
    cursor: pointer;

    background: linear-gradient(180deg, #D24C9F 0%, #A22873 100%);
    box-shadow: 0px 14px 30px rgba(179, 13, 72, 0.16);
    border-radius: 8px;
    border: none;
}

.connect_send_button_words {
    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    /* Black & White/White */

    color: #FFFFFF;
}

.footer_icon_frame{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    float: right;
    width: 96px;
    margin-right: 156px;
    margin-top: 105px;
}

.email_icon{
    position: static;
    width: 32px;
    height: 32px;
}

.twitter_icon{
    position: static;
    width: 32px;
    height: 32px;
    margin-left: 25px;
}

.discord_icon{
    position: static;
    width: 32px;
    height: 32px;
    margin-left: 25px;
}

.footer_words{
    position: relative;
    width: 260px;
    height: 25px;
    margin: 0 auto;
    top: 185px;

    /* Body / sm */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;

    color: rgba(18, 17, 39, 0.56);
}